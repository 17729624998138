import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import bannerImage from "./NVnewlogo.png";
import "./styling/Footer.css";

function Footer() {
  return (
    <footer>
      <Container fluid className=" py-3 mt-5 custom-background">
        <Row className="align-items-center justify-content-center">
          <Col xs="auto" md="3" className="text-center text-md-left pr-md-2">
            <Link
              to="https://www.new-voters.org/about"
              className="text-decoration-none footer-txt"
            >
              About New Voters
            </Link>
          </Col>

          <Col xs="auto" md="2" className="text-center ">
            <img
              src={bannerImage}
              alt="Footer Logo"
              className="footer-logo img-fluid"
            />
          </Col>
          <Col xs="auto" md="3" className="text-center text-md-right pl-md-2">
            <Link
              to="https://www.new-voters.org/terms"
              className="text-decoration-none footer-txt"
            >
              Your Privacy
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
