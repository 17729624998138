// SchoolInputPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SchoolInputPage = () => {
  const [schoolName, setSchoolName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const schoolPath = schoolName.replace(/ /g, "-").toLowerCase();
    navigate(`/success/${schoolPath}`);
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit} className="blue">
        <input
          type="text"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          placeholder="Enter school name..."
        />
        <button type="submit">Generate</button>
      </form>
    </div>
  );
};

export default SchoolInputPage;
