import React, { useState, useRef, useEffect } from "react";
import { Row, Container, Card, Form, Button, Col } from "react-bootstrap";
import { db } from "./config/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import "./styling/Entryform.css";
import Oldenough from "./Oldenough"; // Update 
import Tooyoung from "./Tooyoung"; 
import Commitmentcard from "./Commitmentcard";

const MyForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    birthday: "",
    state: "PA",
    school: "",
  });

  const resultRef = useRef(null);

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue;
    if (type === "checkbox") {
      inputValue = checked;
    } else {
      inputValue = value;
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };
  useEffect(() => {
    if (
      formData.fullName &&
      formData.email &&
      formData.phoneNumber &&
      formData.birthday &&
      formData.school
    ) {
      resultRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [formData]);
  useEffect(() => {
    const utmSource = getUTMSource();
    if (utmSource && utmToSchoolMapping[utmSource]) {
      setFormData((prevState) => ({
        ...prevState,
        school: utmToSchoolMapping[utmSource],
      }));
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const docRef = await addDoc(collection(db, "users"), formData);
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const schools = [
    "Referral",
    "The Baldwin School",
    "Conestoga High School",
    "Downington East High School",
    "North Penn High School",
    "Westtown School",
    "North Allegheny Senior High School",
    "Downingtown East High School",
    "Episcopal Academy",
    "Great Valley High School",
    "Owen J. Roberts High School",
    "Henderson High School",
    "Downingtown West High School",
    "Other",
  ];
  const getUTMSource = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("utm_source");
  };
  const utmToSchoolMapping = {
    westtown: "Westtown School",
    eaa: "Episcopal Academy",
    gvhs: "Great Valley High School",
    ojr23: "Owen J. Roberts High School",
    henderson: "Henderson High School",
    dshs: "Downingtown STEM High School",
    nphs: "North Penn High School",
    nash: "North Allegheny Senior High School",
    chs: "Conestoga High School",
    bs: "The Baldwin School",
    dehs: "Downingtown East High School",
    dwhs23: "Downingtown West High School",
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm="12" md="6" className="custom-text">
          <Card className="pt-2 custom-card custom-text d-flex flex-column align-items-center custom-card-text">
            <Card.Body className="custom-text">
              <Form className="custom-text" onSubmit={handleSubmit}>
                <Form.Group className="mx-md-3">
                  <Form.Label>Full Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mx-md-3 mt-3">
                  <Form.Label>Email * </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mx-md-3  mt-3">
                  <Form.Label>Phone Number * </Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mx-md-3 mt-3 ">
                  <Form.Label>Birthday * </Form.Label>
                  <Form.Control
                    type="date"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mx-md-3 mt-3 ">
                  <Form.Label>State * </Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mx-md-3 mt-3 ">
                  <Form.Label>School/Referral * </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter School"
                    name="school"
                    value={formData.school}
                    onChange={handleChange}
                  />
                  <Form.Label className="formfooter">
                    * You must complete all fields for the next section to
                    appear.
                  </Form.Label>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Conditional rendering of the components based on age */}

      <div ref={resultRef}>
        {formData.fullName &&
        formData.email &&
        formData.phoneNumber &&
        formData.birthday &&
        new Date(formData.birthday).getFullYear() >= 1960 ? (
          new Date(formData.birthday) <= new Date("2006-04-23") ? (
            <Oldenough formData={formData} />
          ) : (
            <Tooyoung formData={formData} />
          )
        ) : null}
      </div>
    </Container>
  );
};

export default MyForm;
