import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import "./styling/commitment.css";
import { db } from "./config/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const Commitment2 = ({ formData }) => {
  const [commitments, setCommitments] = useState({
    pledgeToVote: "no",
    tellPeople: "no",
    learnMore: "no",
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setCommitments((prev) => ({
      ...prev,
      [name]: checked ? "yes" : "no",
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const combinedData = {
      ...formData,
      ...commitments,
    };

    try {
      const docRef = await addDoc(collection(db, "users"), combinedData);
      console.log("Document written with ID: ", docRef.id);
      window.location.href =
        "https://guides.vote/guide/nonpartisan-voters-guide-to-the-2023-carluccio-mccaffery-supreme-court-race";
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="commitment-card-container">
      <Card className="custom-card-text custom-card">
        <Card.Body className="md-flex flex-column justify-content-center align-items-center custom-card-text custom-card">
          <Form className="justify-content-center" onSubmit={handleFormSubmit}>
            <Form.Group
              controlId="commitmentCheck"
              className="justify-content-center "
            >
              <div className="justify-content-center d-flex cardheadertxt text-center">
                <Form.Label>Select Your Commitments</Form.Label>
              </div>
              <Form.Check
                className="mt-md-5 mt-2"
                type="checkbox"
                name="pledgeToVote"
                label="I pledge to vote in the next election I am eligible for"
                onChange={handleChange}
              />
              <Form.Check
                className="mt-md-5 mt-2"
                type="checkbox"
                name="tellPeople"
                label="I commit to telling 3 people who are old enough about the importance of voting in the PA Primary Election"
                onChange={handleChange}
              />
              <Form.Check
                className="mt-md-5 mt-2"
                type="checkbox"
                name="learnMore"
                label="I want to learn more about New Voters / opportunities for awards, internships, career workshops"
                onChange={handleChange}
              />

              <div className="justify-content-center d-flex mt-3 ">
                <Button className="custom-button w-75" type="submit">
                  Submit
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Commitment2;
