import React, { useState } from "react";
import "./styling/Entry.css";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import Entryform from "./Entryform";
import "./styling/Entry.css";
export function Entry({ schoolName }) {
  return (
    <Container fluid className="mt-lg-2 pt-sm-0 mt-sm-0">
      <Row className="justify-content-center">
        <Col xs={12} lg={7} className="mt-3">
          <h2 className="custom-header text-center">
            {schoolName} Your vote in the upcoming primary election holds the key!
          </h2>
          <p className=" custom-body-entry text-center">
          The PA primary election on April 23 2024 will determine who will be on the ballot in November, in critical races such as State Senate and Representative in the General Assembly, District Attorney, Auditor General, and State Treasurer. Make your voice heard and ensure that the person you want in office makes it to the November ballot
          </p>
          <p className=" custom-body-entry text-center">
          This pledge provides opportunities for ALL students, regardless of age or citizenship
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} lg={12} className="d-flex justify-content-center">
          <Entryform></Entryform>
        </Col>
      </Row>
    </Container>
  );
}
