import React from "react";
import { useParams } from "react-router-dom";
import { Entry } from "./Entry";

const Schoolwrapper = () => {
  const { schoolPath } = useParams();
  const schoolName = schoolPath.replace(/-/g, " ").toUpperCase();

  return <Entry schoolName={schoolName} />;
};

export default Schoolwrapper;
