import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Row, Container, Form, Button, Col } from "react-bootstrap";
import Oldenoughnextcard from "./Oldenoughnextcard";
import "./styling/Oldenoughnext.css";

export default function Oldenoughnext() {
  return (
    <Container fluid className="justify-content-center mt-5">
      <Row className="justify-content-center ">
        <Col className="justify-content-center " xs={12} lg={12}>
          <Oldenoughnextcard />
        </Col>
      </Row>
    </Container>
  );
}
