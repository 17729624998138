import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Row, Container, Form, Button, Col } from "react-bootstrap";
import CommitmentCard from "./Commitmentcard";
import "./styling/Oldenough.css";

export default function Oldenough({ formData }) {
  return (
    <Container fluid mt-md-5 pt-md-5>
      <Row className="justify-content-center">
        <Col xs={12} lg={6} className="justify-content-center mt-5">
          <h2 className="text-center oldheader ">
          Congratulations! You are old enough to vote in the April 23, 2024 PA Primary Election
          </h2>
          <h3 className="text-center oldsubheader mt-3  ">
            To register to vote you also need to be a citizen and have lived in
            your election district for 30 days.{" "}
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center  ">
        <Col lg={6} xs={12} className="justify-content-center pt-3  mt-md-5">
          <CommitmentCard redirectUrl="/Oldenoughnext" formData={formData} />
        </Col>
      </Row>
    </Container>
  );
}
