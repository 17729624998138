import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const SuccessPage = () => {
  const { schoolPath } = useParams();
  const navigate = useNavigate();

  const handleCopy = () => {
    const urlToCopy = window.location.origin + "/" + schoolPath;
    navigator.clipboard.writeText(urlToCopy).then(
      () => {
        alert("Link copied to clipboard!");
      },
      (err) => {
        alert("Failed to copy link!");
      }
    );
  };

  const handleNavigate = () => {
    navigate("/" + schoolPath);
  };

  return (
    <div>
      <h2>Success!</h2>
      <p>Your school-specific link is ready.</p>
      <button onClick={handleCopy}>Click to Copy Your Link</button>
      <button onClick={handleNavigate}>Go to Your School Page</button>
    </div>
  );
};

export default SuccessPage;
