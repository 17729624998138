import React, { useState } from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 
import "./styling/Oldenoughnextcard.css";
import { db } from "./config/firebaseConfig";
import { addDoc, collection } from "firebase/firestore";

const Oldenoughnextcard = ({ formData, commitments }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Merge formData and commitments data
    const combinedData = {
      ...formData,
      commitments,
    };

    try {
      const docRef = await addDoc(collection(db, "users"), combinedData);
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    console.log("Selected option:", selectedOption);

    // Check which option was selected and navigate accordingly
    if (selectedOption === "alreadyRegistered") {
      window.location.href =
        "https://register.rockthevote.com/registrants/new?partner=39157&source=pa-sc";
    } else if (selectedOption === "registerToVote") {
      window.location.href =
        "https://www.rockthevote.org/how-to-vote/am-i-registered-to-vote/";
    }
  };

  return (
    <Container fluid className="">
      <Card className="custom-card  custom-card-text pt-md-5 mb-5 ">
        <Card.Body className="d-flex flex-column justify-content-center  custom-card-text">
          <Form onSubmit={handleSubmit} className="mt-2 ">
            <Form.Group controlId="commitmentRadio">
              <div className="justify-content-center d-flex cardheadertxt">
                <Form.Label className="mb-3 custom-card-header-txt text-center">
                  Voter Registration Status
                </Form.Label>
              </div>

              <Form.Check
                className="mb-3 mt-md-5 mt-2 custom-card-text-circle"
                type="radio"
                name="commitment"
                label="Register to Vote"
                value="alreadyRegistered"
                checked={selectedOption === "alreadyRegistered"}
                onChange={handleChange}
              />
              <Form.Check
                className="mb-5 mt-md-5 mt-2 custom-card-text-circle"
                type="radio"
                name="commitment"
                label="Check your Voter Registration Status"
                value="registerToVote"
                checked={selectedOption === "registerToVote"}
                onChange={handleChange}
              />
            </Form.Group>
            <div className="justify-content-center d-flex mt-3 ">
            <Button className="custom-button w-75" type="submit" disabled={!selectedOption}>
  Submit
</Button>

            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Oldenoughnextcard;
