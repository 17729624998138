import Schoolwrapper from "./Schoolwrapper";
import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Entry } from "./Entry"; // Ensure this path and named export is correct
import SchoolInputPage from "./SchoolInputPage";
import SuccessPage from "./SuccessPage";
import Header from "./Header";
import Oldenough from "./Oldenough";
import Tooyoung from "./Tooyoung";
import Oldenoughnext from "./Oldenoughnext";
import Footer from "./Footer";

function App() {
  return (
    <Router>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/success/:schoolPath" element={<SuccessPage />} />
        <Route path="/:schoolPath" element={<Schoolwrapper />} />
        <Route path="/Oldenough" element={<Oldenough />} />
        <Route path="/Oldenoughnext" element={<Oldenoughnext />} />
        <Route path="/Tooyoung" element={<Tooyoung />} />
      </Routes>
      <Footer></Footer>
    </Router>
  );
}

export default App;
