// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKnZT6TE82uq3exeY_7OlbJ1rV_I1HmPE",
  authDomain: "nv-pa-524ab.firebaseapp.com",
  projectId: "nv-pa-524ab",
  storageBucket: "nv-pa-524ab.appspot.com",
  messagingSenderId: "358544707305",
  appId: "1:358544707305:web:9344cefe4dd6365cb4ea78",
  measurementId: "G-LRZX2M2C7K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app); // Initialize Firestore

export { db };
