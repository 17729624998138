import React from "react";
import { Container } from "react-bootstrap";
import bannerImage from "./NVnewlogo.png";
import "./styling/Header.css";

function Header() {
  return (
    <header className="custom-bg-color mb-0 pb-0">
      <Container className="d-flex justify-content-center mt-2 custom-header custom-bg-color">
        <img
          src={bannerImage}
          alt="Banner"
          className="header-image img-fluid"
          style={{ height: "80px" }}
        />
      </Container>
    </header>
  );
}

export default Header;
