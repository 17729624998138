import React, { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./styling/commitment.css";
import Oldenoughnext from "./Oldenoughnextcard";

const CommitmentCard = ({ formData }) => {
  const [pledgeToVote, setPledgeToVote] = useState("no");
  const [tellPeople, setTellPeople] = useState("no");
  const [learnMore, setLearnMore] = useState("no");
  const [renderNextComponent, setRenderNextComponent] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    let shouldRenderNextComponent = false;
  
    switch (value) {
      case "pledgeToVote":
        setPledgeToVote(checked ? "yes" : "no");
        shouldRenderNextComponent = checked;
        break;
      case "tellPeople":
        setTellPeople(checked ? "yes" : "no");
        shouldRenderNextComponent = checked || pledgeToVote === "yes" || learnMore === "yes";
        break;
      case "learnMore":
        setLearnMore(checked ? "yes" : "no");
        shouldRenderNextComponent = checked || pledgeToVote === "yes" || tellPeople === "yes";
        break;
      default:
        break;
    }
  
    // If any of the commitments are set to "yes", we keep the next component rendered.
    if (!shouldRenderNextComponent) {
      shouldRenderNextComponent = pledgeToVote === "yes" || tellPeople === "yes" || learnMore === "yes";
    }
  
    setRenderNextComponent(shouldRenderNextComponent);
  
    if (shouldRenderNextComponent) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  

  return (
    <>
      <div className="commitment-card-container">
        <Card className="custom-card-text custom-card">
          <Card.Body className="md-flex flex-column justify-content-center align-items-center custom-card-text custom-card">
            <Form className="justify-content-center">
              <Form.Group
                controlId="commitmentCheck"
                className="justify-content-center  "
              >
                <div className="justify-content-center d-flex cardheadertxt text-center">
                  <Form.Label>Select Your Commitments</Form.Label>
                </div>
                <div className="custom-card-text">
                  <Form.Check
                    className="mt-md-5 mt-2 custom-card-text"
                    type="checkbox"
                    label="I pledge to vote in this April 23 PA primary election"
                    value="pledgeToVote"
                    onChange={handleChange}
                  />
                  <Form.Check
                    className="mt-md-5 mt-2 custom-card-text"
                    type="checkbox"
                    label="I commit to telling 3 people who are old enough about the importance of voting in the PA Primary Election"
                    value="tellPeople"
                    onChange={handleChange}
                  />
                  <Form.Check
                    className="mt-md-5 mt-2 custom-card-text"
                    type="checkbox"
                    label="I want to learn more about New Voters / opportunities for awards, internships, career workshops"
                    value="learnMore"
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>

      {renderNextComponent && (
        <Oldenoughnext
          formData={formData}
          commitments={{ pledgeToVote, tellPeople, learnMore }}
        />
      )}
    </>
  );
};

export default CommitmentCard;
