import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Row, Container, Form, Button, Col } from "react-bootstrap";
import Commitment2 from "./Commitment2";
import "./styling/Oldenough.css";

export default function Tooyoung({ formData }) {
  return (
    <Container fluid pt-md-5>
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="justify-content-center mt-5">
          <h2 className="text-center oldheader">
            You are not old enough to vote in the PA primary election on April 23 2024, but you can still make an impact!
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center mt-xs-5 ">
        <Col lg={6} className="justify-content-center pt-5 mt-xs-5 mt-lg-2 ">
          <Commitment2 formData={formData} />
        </Col>
      </Row>
    </Container>
  );
}
